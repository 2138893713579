.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* background-color: #282c34; */
  background: linear-gradient(-90deg, #e1e1e1 0%, #e8e8e8 100%);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  /* color: white; */
}

.App-link {
  /* color: #61dafb; */
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

input {
  line-height: 1.5;
  font-size: calc(10px + 1vmin);
  padding: 5px;
  min-width: 100%;
  border-radius: 5px;
  border: #555 1px solid;
}
button {
  line-height: 1.5;
  font-size: calc(10px + 1vmin);
  padding: 5px;
  min-width: 40%;
  border-radius: 5px;
  /* background: linear-gradient(-90deg, #204c67 0%, #274961 100%); */
  background-color: #c62828;
  border: #c62828;
  margin: 0px 2px;
  color: #fff
}
.qrcode{
  margin: 40px 0px;
}

canvas {
  display: none;
}

.madeby {
  padding-top: 30px;
  font-size: 14px;
}
.form {
  background-color: #fff;
  padding: 40px;
  box-shadow: rgba(255, 255, 255, 0.35) 0px 10px 30px;
}